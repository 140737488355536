<template>
    <div class="clientcabin clientcabin-blog">
        <v-container class="black--text">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/almost-fired/1.png"
                        width="100%"
                    />
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        We almost got fired: 14,700 clicks at 10x ROAS!
                    </p>

                    <v-card
                        class="blueish black--text px-6 py-5 rounded-lg my-6 elevation-0"
                    >
                        <p class="font-weight-bold">Article at-a-glance</p>
                        <ul>
                            <li>
                                Content marketing is a great way to build trust,
                                increase brand awareness, and educate your
                                target audiences. (In fact,
                                <a
                                    href="https://nytlicensing.com/latest/trends/impressive-content-marketing-statistics/"
                                    target="_blank"
                                >
                                    80% of content marketers say that brand
                                    awareness is their top priority
                                </a>
                                )
                            </li>
                            <li>
                                The right content strategy helps you connect
                                with consumers early in the sales funnel and can
                                even be used to snatch traffic from competitors.
                            </li>
                            <li>
                                {{ company }}’s organic traffic Done-For-You
                                model helped a company in the health space go
                                from almost zero traffic to thousands of clicks
                                in just 3 months.
                            </li>
                            <li>
                                However, the CEO didn’t like the content, almost
                                fired us, and lost most of the traffic we had
                                built.
                            </li>
                            <li>
                                We’ve broken down what happened, what we
                                learned, and how they got to a position of 10X
                                Return On Ad Spend by trusting the
                                {{ company }} process.
                            </li>
                        </ul>
                    </v-card>
                    <p>
                        The customer is always right- except when they’re wrong.
                    </p>
                    <p>
                        We experienced this earlier this year, with a customer
                        who didn’t trust the {{ company }} process despite all
                        the numbers indicating that they should, resulting in
                        that we almost got fired. Luckily, they turned around in
                        the end and are now receiving 20,000+ extra visits per
                        month, which we can prove come directly from the
                        {{ company }} “Create, Repurpose, Distribute” model.
                    </p>
                    <p>
                        Here’s what happened and what we learned in the process:
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        We Covered Competitors
                    </p>
                    <p>
                        We started working with the marketing manager for a
                        company in the health industry, creating content that
                        mentioned competition in comparison with our client’s
                        products. By providing potential customers with
                        informational content and accurate information that
                        still favored our client, we could target traffic
                        looking for educational content as well as the traffic
                        looking for the competitors’ products.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/almost-fired/1.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        There’s a difference between knowing your competition
                        and bad-mouthing. Comparative content should be
                        informative; people want to know what you can do for
                        them, not what someone else can’t.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Great Initial Results
                    </p>
                    <p>
                        What we did worked! After just a couple of months of
                        publishing, traffic started going up, and the client had
                        a positive ROI.
                    </p>
                    <p class="text-h4 mt-8 text-center">CEO Hated It!</p>
                    <p>
                        To our surprise, the CEO (who hadn’t been directly
                        involved and didn’t know our process) freaked out about
                        having competitors mentioned on his website, didn’t like
                        our writing style, and decided to delete all the content
                        we had created (except for one piece that he liked)
                        telling the marketing manager to fire us.
                    </p>
                    <p>
                        The marketing manager, who had a deeper understanding of
                        the {{ company }} strategy and the results, asked the
                        CEO to hold off while trying to figure it out.
                    </p>
                    <p class="font-weight-bold">But it was too late.</p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/almost-fired/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        The numbers speak for themselves. It takes time to build
                        traffic, but evidently, it doesn’t take long to wreck
                        results.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        CEO Loses All Traffic!
                    </p>
                    <p>
                        As you can see in the picture above, there was a sharp
                        drop in traffic after the CEO had deleted almost all of
                        our content.
                    </p>
                    <p class="text-h4 mt-8 text-center">What We Learned</p>
                    <p>
                        But hey, mistakes are great opportunities to learn! We
                        now know that we need to explain the strategy more
                        clearly. The CEO didn’t like the idea of competitors
                        being mentioned on the site. What he didn’t understand
                        was that
                        <b>
                            this content was buried deep inside the website so
                            that regular surface readers wouldn’t see this
                            content.
                        </b>
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        How the Hyper-Targeted Traffic Strategy Works
                    </p>
                    <p>
                        Our strategy is all about
                        <b>hyper-targeted traffic</b>
                        , providing potential customers with just the type of
                        information they’re looking for. So, if someone, as an
                        example, searched for
                        <i>“Organic *health product* for fatigue”</i>
                        they might find content directly linking to the client’s
                        main page or product page (if they are ranking for it).
                        But if they searched for
                        <i>
                            “Is *product x* or *product z* better for fatigue”
                        </i>
                        or
                        <i>“*Competitor name product* for fatigue,”</i>
                        they would find our content mentioning competition.
                    </p>
                    <p class="text-h4 mt-8 text-center">CEO’s Realization</p>
                    <p>
                        Shortly after deleting all the content, the CEO realized
                        that by targeting audiences looking for competing
                        brands, his company would get traffic from people who
                        hadn’t even considered his brand to begin with.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Why You Should Prioritize Informative Content
                    </p>
                    <p>
                        <a
                            href="https://blog.hubspot.com/marketing/how-consumers-learn-about-products"
                            target="_blank"
                        >
                            Statistics from HubSpot
                        </a>
                        suggest that 34% of consumers prefer to learn about new
                        products by searching the internet, especially among
                        generations with the most spending power. While social
                        media is getting increasingly popular among younger
                        generations, 86% of consumers still say search engines
                        are the best way to find information, underlining the
                        potential of educational content.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Months Lost, But We Restart
                    </p>
                    <p>
                        Eventually we got the go-ahead from the CEO to restart
                        this strategy and fixed the content issues to align more
                        with their brand (it can take a few months to get this
                        right). But unfortunately, they still lost months of
                        progress. Not just months to get back to where they
                        were, as we had to start almost from scratch. Had they
                        just kept going in March, they could have enjoyed even
                        greater growth now.
                    </p>
                    <p class="text-h4 mt-8 text-center">Huge Results</p>
                    <p>
                        It took a while to regain this momentum, but several
                        months on, they are trending
                        <b>20,000+ extra visits per month</b>
                        (and probably 2x that… it’s just what we can easily
                        prove came from our work). This is trending to get about
                        <b>
                            $50,000/mo worth in free, highly targeted,
                            high-converting traffic…
                        </b>
                        a lot of which we steal from their competition, and it
                        just keeps going up.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/almost-fired/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        While it took a while to get back on track, the company
                        is now getting more traffic than ever.
                    </p>
                    <p class="text-h4 mt-8 text-center">Record ROAS</p>
                    <p>
                        After a few twists and turns, the client is finally
                        getting to see the power of the {{ company }} effect.
                        Return on their spend with us, including all of our
                        fees, is now well over 10x and keeps going up each
                        month.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        10 Months Later, These are the Results
                    </p>
                    <p>
                        Since starting to work with us in December 2023, here
                        are the benefits the client enjoys today:
                    </p>
                    <ul class="pb-4">
                        <li>
                            Over
                            <b>51,000 highly targeted clicks</b>
                            to their site minimum (this is what we can 100%
                            prove was us… the real results could actually be
                            double)
                        </li>
                        <li>
                            Overall
                            <b>organic traffic is up by 300%</b>
                        </li>
                        <li>
                            The last 28 days brought
                            <b>14,700 clicks at over 10x ROAS</b>
                            (“Return On Ad Spend” - includes ALL costs)
                        </li>
                        <li>
                            The current trend for the next 28 days is 20,000+
                            clicks, with
                            <b>ROAS going up 25%</b>
                        </li>
                    </ul>
                    <p class="text-h4 mt-8 text-center">
                        Want the Same Results?
                    </p>
                    <p>Quite an adventure!</p>
                    <p>
                        Ever since, they have been an awesome client to work
                        with, and we’re used to that initial skepticism as very
                        few platforms ever truly deliver.
                    </p>
                    <p>We stand apart in that sense.</p>
                    <p>
                        Now they trust us; we can implement really effective
                        {{ company }}
                        strategies that pay off big time, which is why their
                        traffic is heading to the moon!
                    </p>
                    <p>
                        If that makes sense and you like the sound of it…
                        <router-link to="/info" class="font-weight-bold">
                            book a call with us here
                        </router-link>
                        .
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class AlmostFired extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>
